@import '../variables.scss';

.PendingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;

  .title {
    font-weight: 900;
    font-size: 1.5rem;
    line-height: 120%;
    color: #252f35;
    margin-bottom: 100px;
  }
  .subtitle {
    margin-top: 22px;
    font-size: 0.875rem;
    line-height: 160%;
    color: #7f9099;
  }
  .btn--session {
    margin-top: 20px;
    width: 100%;
  }

}
