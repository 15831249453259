// Proxima
@font-face {
  font-family: 'proxima_nova';
  src: url('../fonts/proxima_nova_thin.eot');
  src: url('../fonts/proxima_nova_thin.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proxima_nova_thin.woff') format('woff'),
    url('../fonts/proxima_nova_thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'proxima_nova';
  src: url('../fonts/proxima_nova_light.eot');
  src: url('../fonts/proxima_nova_light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proxima_nova_light.woff') format('woff'),
    url('../fonts/proxima_nova_light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'proxima_nova';
  src: url('../fonts/proxima_nova_regular.eot');
  src: url('../fonts/proxima_nova_regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proxima_nova_regular.woff') format('woff'),
    url('../fonts/proxima_nova_regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'proxima_nova';
  src: url('../fonts/proxima_nova_semibold.eot');
  src: url('../fonts/proxima_nova_semibold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proxima_nova_semibold.woff') format('woff'),
    url('../fonts/proxima_nova_semibold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'proxima_nova';
  src: url('../fonts/proxima_nova_bold.eot');
  src: url('../fonts/proxima_nova_bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proxima_nova_bold.woff') format('woff'),
    url('../fonts/proxima_nova_bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
