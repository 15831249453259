.KeyboardShortcut {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__left,
  &__right {
    > div {
      margin-bottom: 10px;
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 100%;
      letter-spacing: 0.2px;
      color: #4f4f4f;
      display: flex;
      align-items: center;

      span:nth-last-child(1) {
        margin-left: 10px;
      }
    }
  }
  .Shortcut {
    font-size: 0.625rem;
    line-height: 12px;
    text-align: center;
    color: #ffffff;
    background: #49575e;
    border-radius: 2px;
    width: 5.5px;
    color: #fff;
    padding: 4px 10px;
    margin-right: 4px;

    &--transparent {
      opacity: 0;
    }
  }
}
