@import '../variables.scss';

.spinner {
  &--on {
    width: 40px;
    height: 40px;
    position: absolute;
    z-index: 100;
  }
  &--off {
    visibility: hidden;
  }
  &__innerBounce,
  &__outerBounce {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $color-blue;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: sk-bounce 2s infinite ease-in-out;
  }
  &__innerBounce {
    animation-delay: -1s;
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
