@import '../variables.scss';

.Navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  font-size: 1rem;
  background: #068cf7;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  z-index: 102;

  &__title {
    margin-left: $gutter;
    color: $color-white;
    text-decoration: none;
    font-weight: 600;
  }
  &__logo {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
  }
}

.Navbar--collapse {
  margin-right: $gutter;

  .NavItem {
    color: $color-white;
    font-weight: 700;
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 101;
    li {
      width: 100%;
      .NavLink {
        display: flex;
        align-items: center;
        height: 56px;
        transition: background 0.4s;
        padding: 0 $gutter;
        font-size: 0.875rem;
        line-height: 17px;
        letter-spacing: 0.6px;
        color: #ffffff;

        &__avatar {
          width: 32px;
          height: 32px;
        }
        &__name {
          margin-left: 12px;
          margin-right: 4px;
          display: flex;
          flex-direction: column;
          &--sub {
            font-size: 0.6875rem;
            line-height: 13px;
            color: #ffffff;
            opacity: 0.8;
          }
        }

        img {
          margin: 0 0 4px 4px;
        }
      }
    }

    &__menu {
      &:hover {
        .NavItem__submenu {
          display: block;
        }
      }
    }

    &__submenu {
      font-weight: 500;
      display: none;
      min-width: 288px;
      position: absolute;
      right: 0;
      background: $color-white;
      color: $color-black;
      z-index: 200;
      box-shadow: 0px 10px 30px -10px rgba(0, 0, 0, 0.4);

      .item {
        display: flex;
        align-items: center;
        border-top: 1px solid rgba($color-light-grey, 0.3);
        min-height: 38px;
        padding: 0 1.5rem;
        font-size: 0.875rem;
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }

        &--stat {
          color: #1a1919;
          font-weight: 900;
          display: block;
          line-height: 150%;
          letter-spacing: 0.03rem;
          padding: 12px 24px;

          .stat {
            &__header {
              font-size: 0.875rem;
              font-feature-settings: 'pnum' on, 'lnum' on;
              color: #1a1919;
              margin-bottom: 12px;
            }

            &__description {
              display: flex;
              flex-direction: row;
            }
            &__today,
            &__yesterday {
              display: flex;
              flex-direction: row;

              img {
                width: 24px;
                height: 24px;
                margin-right: 9px;
              }
            }

            &__yesterday {
              margin-left: auto;
              margin-right: 24px;
            }

            &__info {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            &__day {
              font-size: 0.625rem;
              line-height: 125%;
              color: #7f9099;
              font-weight: normal;
              margin-top: 6px;
            }
            &__number {
              font-size: 1.5rem;
            }
          }
        }

        &--keyboard {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
