@import '../variables.scss';

.AnnotationContainer {
  width: 50%;
  height: 100%;
  background-color: white;
  overflow-y: auto;
  color: $color-default;
  padding: 2rem 4rem 2rem;
  box-sizing: border-box;
}

.Annotation {
  width: 400px;
  margin-left: auto;
  margin-right: 48px;
}

.Annotation__header {
  margin-top: 28px;
  .title {
    font-weight: 900;
    font-size: 1.5rem;
    line-height: 120%;
    color: #252f35;
  }

  .subtitle {
    margin-top: 12px;
    font-size: 0.875rem;
    line-height: 120%;
    color: #7f9099;
  }
}

.Annotation__topicRules {
  margin: $gutter 0;

  .more {
    font-size: 0.875rem;
    line-height: 17px;
    color: #2185d0;
    margin-top: 26px;
    cursor: pointer;
  }

  .TopicRule {
    cursor: pointer;
    position: relative;
    background: #ffffff;
    border: 1px solid #e9eef3;
    box-sizing: border-box;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    margin-top: 20px;
    padding: 26px;

    * &.active {
      border: 2px solid #0083e3;
    }

    &__information {
      margin-left: 78px;
    }

    &__name {
      font-weight: bold;
      font-size: 0.875rem;
      line-height: 120%;
      color: #1a1919;
    }

    &__description {
      font-weight: normal;
      font-size: 0.75rem;
      line-height: 120%;
      color: #7f9099;
      max-width: 90%;
    }

    &__icon {
      position: absolute;
      width: 46px;
      height: 46px;
      top: 20%;

      &--positive_intent {
        background-image: url('../../images/topic-rules/positive_intent.svg');
        background-repeat: no-repeat;
      }
      &--negative_intent {
        background-image: url('../../images/topic-rules/negative_intent.svg');
        background-repeat: no-repeat;
      }
      &--meeting_request {
        background-image: url('../../images/topic-rules/meeting_request.svg');
        background-repeat: no-repeat;
      }
      &--ooo {
        background-image: url('../../images/topic-rules/ooo.svg');
        background-repeat: no-repeat;
      }
      &--bounced {
        background-image: url('../../images/topic-rules/bounced.svg');
        background-repeat: no-repeat;
      }
      &--rfi {
        background-image: url('../../images/topic-rules/rfi.svg');
        background-repeat: no-repeat;
      }
      &--using_other_solution {
        background-image: url('../../images/topic-rules/using_other_solution.svg');
        background-repeat: no-repeat;
      }
      &--referral {
        background-image: url('../../images/topic-rules/referral.svg');
        background-repeat: no-repeat;
      }
      &--dnc {
        background-image: url('../../images/topic-rules/dnc.svg');
        background-repeat: no-repeat;
      }
      &--not_right_time {
        background-image: url('../../images/topic-rules/not_right_time.svg');
        background-repeat: no-repeat;
      }
      &--not_right_person {
        background-image: url('../../images/topic-rules/not_right_person.svg');
        background-repeat: no-repeat;
      }
      &--neutral_intent {
        background-image: url('../../images/topic-rules/neutral_intent.svg');
        background-repeat: no-repeat;
      }
    }
    &--active {
      content: '';
      position: absolute;
      background-image: url('../../images/active-check.svg');
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
      top: 36%;
      right: 19px;
    }

    &--inactive {
      content: '';
      position: absolute;
      background-image: url('../../images/inactive-check.png');
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
      top: 36%;
      right: 19px;
    }
  }
}
