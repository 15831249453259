// Global
@import 'partials/fontface.scss';
@import 'partials/helper.scss';
@import 'partials/container.scss';
@import 'partials/form.scss';
@import 'partials/modal.scss';

@import 'components/toast.scss';
@import 'components/annotation.scss';
@import 'components/email.scss';
@import 'components/login.scss';
@import 'components/navbar.scss';
@import 'components/keyboard-shortcut-modal.scss';
@import 'components/welcome.scss';
@import 'components/spinner.scss';
@import 'components/pending.scss';

@import './variables.scss';

html {
  font-family: $font-family;
  font-size: $font-size-base;
  height: 100%;
  line-height: $line-height-base;
  letter-spacing: 0.5px;
}

body {
  height: 100%;
  margin: 0;
}
