@import '../variables.scss';

.LoginContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: #068cf7;

  .LoginForm {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 414px;
    height: 308px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    margin-top: $gutter;

    &__title {
      text-align: center;
      color: #000;
      font-weight: 600;
      margin-bottom: 18px;
    }

    &__field {
      width: 304px;
      height: 40px;
      border: 1px solid $color-neutral;
      border-radius: 5px;
      display: flex;
      align-items: center;
      color: $color-neutral;
      background: $color-white;
      margin-top: 13px;
      padding-left: 16px;
    }

    input {
      width: 80%;
      border: none;
      outline: none;
      margin-left: $gutter;
      font-size: 1rem;
      font-weight: 500;
      color: #444;
      line-height: 19px;
    }
  }

  .footer {
    font-size: 12px;
    font-weight: 300;
    bottom: 16px;
    position: absolute;
    color: rgba($color-white, 0.4);
    &--white {
      color: $color-white;
      text-decoration: none;
    }
  }
}
