.container {
  display: flex;
  position: absolute;
  width: 100vw;
  height: calc(100vh - 60px);

  &.loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
