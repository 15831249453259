// Fonts
// ----------
$font-size-base: 16px;
$line-height-base: 19px;
$font-family: 'proxima_nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;

// Text Colors
// ----------
$color-black: #000;
$color-white: #fff;
$color-active: #0083e3;
$color-muted: #a7aaae;
$color-default: #1a1919;
$color-disabled: #bbbbbb;
$color-light-blue: #718fcd;
$color-blue: #67b0c0;
$color-light-grey: #a1a1a1;
$color-grey: #7e7e7e;
$color-menu: #e9f4fc;
$color-positive: #2eb6a3;
$color-neutral: #dbdbe2;
$color-warning: #e5dfa2;
$color-yellow: #ffffef;
$color-error: #9b4c49;

// Layout
// ----------
$gutter: 1.5rem;
$gutter-double: 3rem;

// Background Colors
// ----------
$bg-color-email: #eff1f5;
$bg-color-disabled: #f1f2f2;
$bg-color-positive: #18a4ab;
$bg-color-positive-dark: darken($bg-color-positive, 5%);
$bg-color-positive-darker: darken($bg-color-positive, 15%);
$bg-color-neutral: $color-neutral;
$bg-color-neutral-alt: #c0c5cc;
$bg-color-blue: #67b0c0;
$bg-color-blue-dark: #5692a0;
$bg-color-blue-darker: #314f5f;

// Box Shadow
// ----------
$box-shadow-divider: inset 10px 0 60px -20px rgba($color-black, 0.2),
  inset 10px 0 20px -10px rgba($color-black, 0.1);
$box-shadow-card: -2px 2px 4px rgba(0, 0, 0, 0.1);

// Responsive Breakpoints
// ----------
// Based on Semantic-UI breakpoints - https://semantic-ui.com/elements/container.html
$display-width-tablet: 768px;
$display-width-small: 992px;
$display-width-large: 1200px;
