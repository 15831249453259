@import '../variables.scss';

.EmailContainer {
  width: 50%;
  height: 100%;
  overflow-y: auto;
  background-color: $bg-color-email;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Email {
  overflow: scroll;
  padding-left: 64px;
  padding-right: 64px;

  &__subject {
    padding-bottom: 18px;
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 24px;
    color: #252f35;
  }

  &__body {
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    padding: 14px 22px 16px 18px;
  }
}

.Email__meta {
  display: flex;
  padding-bottom: 17px;
  margin-bottom: 19px;
  border-bottom: 1px solid #e9eef3;

  &--avatar {
    width: 28px;
    height: 28px;
    text-transform: uppercase;
    border-radius: 28px;
    background: #fe6ba5;
    color: $color-white;
    position: relative;

    .initial {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &--from-to {
    margin-left: 10px;
  }
  &--from {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 128%;
    color: #000000;
    mix-blend-mode: normal;
  }
  &--to,
  &--ccs {
    font-size: 0.75rem;
    span:nth-child(1) {
      font-weight: 600;
      color: #000000;
    }
    span:nth-child(2) {
      font-weight: normal;
      color: #7f9099;
    }
  }
}

.Email__content {
  width: 100%;
  color: #000000;
  white-space: pre-wrap;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 120%;
  mix-blend-mode: normal;
  opacity: 0.7;
}
