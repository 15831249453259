.Toastify {
  .CustomToastContainer {
    top: 4em;
    left: 42%;

    .CustomToast {
      min-height: unset;
      width: 600px;
      border-radius: 5px;

      &--success {
        background: #d7ebe1;
        border: 1px solid #389c67;
      }

      &--warning {
        background: #ffeecd;
        border: 1px solid #ffaa04;
      }

      &--info {
        background: #d8e8f6;
        border: 1px solid #3a8dd1;
      }

      &--error {
        background: #efd9d9;
        border: 1px solid #ad4040;
      }

      .CustomToastBody {
        padding-left: 24px;
        color: #252f35;
        font-size: 0.875em;
        line-height: 150%;

        &--success {
          &:before {
            content: '';
            background-image: url('../../images/toast/success.png');
            background-repeat: no-repeat;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 10px;
            left: 10px;
          }
        }

        &--warning {
          &:before {
            content: '';
            background-image: url('../../images/toast/warning.png');
            background-repeat: no-repeat;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 10px;
            left: 10px;
          }
        }

        &--info {
          &:before {
            content: '';
            background-image: url('../../images/toast/info.png');
            background-repeat: no-repeat;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 10px;
            left: 10px;
          }
        }

        &--error {
          &:before {
            content: '';
            background-image: url('../../images/toast/error.png');
            background-repeat: no-repeat;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 10px;
            left: 10px;
          }
        }
      }

      .Toastify__close-button {
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
}
