form,
.form-blank,
.form-inline {
  width: 100%;
  box-sizing: border-box;

  &[data-loading='true'] {
    button[type='submit'] {
      position: relative;
      color: transparent;
      pointer-events: none;

      &::before,
      &::after,
      span::before,
      span::after {
        content: '';
        position: absolute;
        background-color: #f0f0f0;
        height: 80%;
        width: 4px;
        top: 10%;
        display: inline-block;
        left: 50%;
        animation: sk-stretchdelay 1.2s infinite ease-in-out;
      }

      &::before {
        margin: 0 3px 0 -4px;
        animation-delay: -1s;
      }

      &::after {
        margin: 0 3px 0 4px;
        animation-delay: -0.9s;
      }

      span::before {
        margin: 0 3px 0 -12px;
        animation-delay: -1.1s;
      }

      span::after {
        margin: 0 3px 0 12px;
        animation-delay: -0.8s;
      }
    }
  }

  a {
    text-decoration: none;
    color: #333;
  }

  .errors {
    color: #eb462a;
  }

  .center-errors {
    color: #eb462a;
    font-weight: 600;
    text-align: center;
  }
}

.center-errors {
  color: #eb462a;
  font-weight: 600;
  text-align: center;
}

.form-inline {
  width: 100%;

  label {
    padding: 0 16px 0 0;
  }
}
input:disabled {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAIklEQVQIW2NkQAKrVq36zwjjgzhhYWGMYAEYB8RmROaABADeOQ8CXl/xfgAAAABJRU5ErkJggg==)
    repeat;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
.strikethrough {
  text-decoration: line-through;
}

button {
  cursor: pointer;
  display: inline-block;
  padding: 0.75rem 1.5rem;
  font-weight: 500;
  font-size: 1rem;
  border: none;
  border-radius: 0.4rem;

  &:focus {
    outline: 0;
  }

  &.btn--continue {
    background-color: $bg-color-positive;
    color: $color-white;
    border-radius: 4px;
    margin: 2.5rem 1rem 0 0;
    &:hover {
      background-color: $bg-color-positive-dark;
    }
    &:active {
      background-color: $bg-color-positive-darker;
    }
  }

  &.btn--skip {
    background-color: $color-white;
    color: $color-black;
    margin: 2.5rem 1rem 0 0;
  }

  &.btn--disabled {
    cursor: not-allowed;
    background-color: $bg-color-neutral-alt;
    color: $color-black;
    margin: 2.5rem 1rem 0 0;
  }

  &.btn--login {
    width: 320px;
    height: 40px;
    margin-top: 15px;
    border-radius: 4px;
    border: none;
    outline: none;
    background-color: #18a4ab;
    color: $color-white;

    &:hover {
      opacity: 0.8;
      transition: all 0.4s ease;
    }
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}
