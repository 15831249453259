@import '../variables.scss';

.img {
  &--xs {
    width: 10px;
  }
  &--sm {
    width: 14px;
  }
  &--md {
    width: 32px;
  }
  &--lg {
    width: 40px;
  }
  &--center {
    transform: translate(0%, -25%);
  }
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.neutral-color {
  color: $color-neutral;
}

.Avatar {
  width: 28px;
  height: 28px;
  text-transform: uppercase;
  border-radius: 28px;
  background: #fe6ba5;
  color: $color-white;
  position: relative;

  &__initial {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
