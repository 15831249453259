.WelcomeDescriptionContainer {
  width: 50%;
  height: 100%;
  background-color: white;
  overflow-y: auto;
  color: $color-default;
  padding: 2rem 4rem 2rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.WelcomeDescription {
  width: 400px;
  margin-left: auto;
  margin-right: 48px;
  .title {
    font-weight: 900;
    font-size: 1.5rem;
    line-height: 120%;
    color: #252f35;
  }
  .subtitle {
    margin-top: 22px;
    font-size: 0.875rem;
    line-height: 160%;
    color: #7f9099;
  }
  .btn--session {
    margin-top: 20px;
    width: 100%;
  }
}

.WelcomeImageContainer {
  width: 50%;
  height: 100%;
  overflow-y: auto;
  background-color: $bg-color-email;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.WelcomeImage {
  padding-left: 64px;
  padding-right: 64px;
}
